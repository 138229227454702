import onDomReady from "./onDomReady";
// filtern der bilder nach name=ShuffleFilter
// auswahl der bilder in PROFILE.JS
export class Imagepool {
    constructor() {}
    initialize(naja) {
        const shuffleFilter = (element) => {
            let inputs;
            let shuffleInstance;
            let shuffles;
            let ALLButtons = element.querySelectorAll(
                "input[name=ShuffleFilter]"
            );
            console.log("element", element);
            for (let _counter = 1; _counter < 10; ++_counter) {
                shuffles = document.getElementById("pool" + _counter);
                if (shuffles) {
                    console.log(shuffles);
                    shuffleInstance = new Shuffle(shuffles, {
                        itemSelector: ".galleryitem" + _counter,
                    });

                    inputs = element.querySelectorAll(
                        "#tab-pane-PoolImg" +
                            _counter +
                            " input[name=ShuffleFilter]"
                    );
                    if (inputs) {
                        Array.from(inputs).forEach((input) => {
                            console.log(input);
                            let filterGroup ;
                            input.addEventListener("click", (event) => {
                                console.log(event.target.value);
                                Array.from(ALLButtons).forEach((input) => {
                                    console.log(input);
                                    input.classList.remove("active");
                                });
                                event.target.classList.add("active");
                                if (event.target.value == "Alle") {
                                    filterGroup = Shuffle.ALL_ITEMS;
                                } else {
                                    filterGroup = event.target.value;
                                }
                                
                                console.log("filterGroup" , filterGroup);
                                shuffleInstance.filter(filterGroup);
                            });
                        });
                    }
                    /*
                images = document.querySelectorAll(
                    ".galleryitem" + _counter
                );
                if (images) {
                    Array.from(images).forEach((image) => {
                        image.addEventListener("change", (event) => {
                            console.log(event.target.value);
                        });
                    });
                }
*/
                }
            }
        };

        onDomReady(() => shuffleFilter(document));
        naja.snippetHandler.addEventListener("afterUpdate", (event) =>
            shuffleFilter(event.detail.snippet)
        );
    }
}
