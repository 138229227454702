import onDomReady from "./onDomReady";
import "trumbowyg";
import icons from "../../node_modules/trumbowyg/dist/ui/icons.svg";
import "../../node_modules/trumbowyg/plugins/colors/trumbowyg.colors.js";

export class trumbo {
    constructor() {
        console.log("constructor trumbo");
    }
    initialize(naja) {
        const trumbo = (element) => {
            if (element.querySelector(' textarea[name=description]') ) {
                window.$.trumbowyg.svgPath = icons;
                window.$(' textarea[name=description]').trumbowyg({
                    autogrowOnEnter: true,
                    btns: [
                        ['viewHTML'],
                        ['undo', 'redo'], // Only supported in Blink browsers
                        ['foreColor', 'backColor'],
                        ['formatting'],
                        ['strong', 'em', 'del'],
                        ['superscript', 'subscript'],
                        ['link'],
                        ['insertImage'],
                        ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
                        ['unorderedList', 'orderedList'],
                        ['horizontalRule'],
                        ['removeformat'],
                        ['fullscreen']
                    ]
                });
            }
        }

        onDomReady(() => trumbo(document));
        // naja.addEventListener("complete", (event) => {
        naja.snippetHandler.addEventListener("afterUpda", (event) => {
            trumbo(event.detail.snippet);
        });
    }
}
