
export class SpinnerExtension {
    constructor(defaultLoadingIndicatorSelector) {
        this.defaultLoadingIndicatorSelector = defaultLoadingIndicatorSelector;
        console.log('constructor this.defaultLoadingIndicator', this.defaultLoadingIndicator);
    }

    initialize(naja) {

        if (document.readyState === 'loading') {
            document.addEventListener('DOMContentLoaded', () => {
                this.defaultLoadingIndicator = document.querySelector(this.defaultLoadingIndicatorSelector);
            });
        } else {
            this.defaultLoadingIndicator = document.querySelector(this.defaultLoadingIndicatorSelector);
        }
        // console.log('initialize this.defaultLoadingIndicator', this.defaultLoadingIndicator);
        naja.uiHandler.addEventListener('interaction', (event) => {
            // console.log(`SpinnerExtension interaction event: `, event.detail);
            this.locateLoadingIndicator.bind(this);
            // console.log('SpinnerExtension interaction2');
        });

        naja.addEventListener('before', (event) => {
            console.log('SpinnerExtension before');
            // document.getElementById('ajax-spinner').style.visibility = 'visible';
            // document.getElementById('ajax-spinner').style.display = 'block';
        });

        naja.addEventListener('start', this.showLoader.bind(this));
        naja.addEventListener('complete', this.hideLoader.bind(this));
    }

    locateLoadingIndicator(event) {
        const loadingIndicator = document.querySelector('[data-loading-indicator]');
        event.detail.options.loadingIndicator = loadingIndicator || this.defaultLoadingIndicator;
    }
    showLoader(event) {
        console.log('SpinnerExtension showLoader', this.defaultLoadingIndicatorSelector);
        if (this.defaultLoadingIndicator) this.defaultLoadingIndicator.style.visibility = 'visible';
    }
    hideLoader(event) {
        console.log('SpinnerExtension hideLoader');
        
        if (this.defaultLoadingIndicator) this.defaultLoadingIndicator.style.visibility = 'hidden';

        document.querySelector("body").style.overflow = '';
        document.querySelector("body").style.paddingRight = '';
    }

};

