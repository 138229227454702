import onDomReady from './onDomReady'

export class linkconnlog {
    constructor(linkconneseletor) {
        console.log('constructor linkconn')
        this.linkconneseletor = linkconneseletor || '.linkConnLog'
    }
    initialize(naja) {
        const linkconnlog = (element) => {
            let linkConnLogElems = element.querySelectorAll(
                this.linkconneseletor
            )
            if (linkConnLogElems) {
                linkConnLogElems.forEach((el) => {
                    el.addEventListener('click', (e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        // naja.makeRequest("POST", "/uv?do=linkConnStat", {
                        naja.makeRequest('GET', '/uv/linkConnStat', {
                            clickedlink: document.location.toString(),
                        })
                            .then((payload) => {
                                /* process payload */
                            })
                            .catch((error) => {
                                /* handle error */
                            })
                        return false
                    })
                })
            }
        }
        onDomReady(() => linkconnlog(document))
        naja.snippetHandler.addEventListener('afterUpdate', (event) =>
            linkconnlog(event.detail.snippet)
        )
    }
}

export class linkconn {
    constructor() {
        console.log('constructor linkconn')
    }
    initialize(naja) {
        const linkconn = (element) => {
            const copyForms = element.querySelectorAll('form.linkConn')
            console.log(copyForms)
            if (copyForms) {
                copyForms.forEach((form) => {
                    console.log(form)
                    form.addEventListener(
                        'submit',
                        (event) => {
                            event.preventDefault()
                            event.stopPropagation()
                            // console.log('form submit', form)

                            let phone = form.querySelector(
                                'input[name="phone"]'
                            )
                            if (phone) phone = phone.value

                            let email = form.querySelector(
                                'input[name="email"]'
                            )
                            if (email) email = email.value
                            
                            let linkconntemplate
                            // wir wollen mail schicken!
                            if (event.submitter.dataset.linkconnemailtarget)
                                linkconntemplate = form.querySelector(
                                    'textarea[name="' +
                                        event.submitter.dataset
                                            .linkconnemailtarget +
                                        '"]'
                                )
                            // wir wollen in zwischenablage kopieren!
                            if (event.submitter.dataset.linkconntarget)
                                linkconntemplate = form.querySelector(
                                    'textarea[name="' +
                                        event.submitter.dataset.linkconntarget +
                                        '"]'
                                )
                            if (linkconntemplate.value)
                                console.log(
                                    'linkconntemplate.value',
                                    linkconntemplate.value
                                )
                            let linkconntemplatevalue = linkconntemplate.value
                            let first_name = form.querySelector(
                                'input[name="first_name"]'
                            ).value
                            let last_name = form.querySelector(
                                'input[name="last_name"]'
                            ).value
                            let company_name = form.querySelector(
                                'input[name="company_name"]'
                            ).value
                            let invite = form.querySelector(
                                'input[name="invite"]'
                            ).value
                            let target = form.querySelector(
                                'input[name="target"]'
                            ).value

                            let url =
                                form.querySelector('input[name="url"]').value
                            let name =
                                form.querySelector('input[name="name"]').value

                            console.log(
                                first_name,
                                last_name,
                                company_name,
                                invite,
                                email
                            )
                            linkconntemplatevalue =
                                linkconntemplatevalue.replaceAll(
                                    '%first_name%',
                                    first_name
                                )
                            linkconntemplatevalue =
                                linkconntemplatevalue.replaceAll(
                                    '%last_name%',
                                    last_name
                                )
                            linkconntemplatevalue =
                                linkconntemplatevalue.replaceAll(
                                    '%company_name%',
                                    company_name
                                )

                            // console.log(linkconntemplatevalue)

                            // copy to clipboard^
                            navigator.clipboard
                                .writeText(linkconntemplatevalue)
                                .then(
                                    function () {
                                        console.log(
                                            'Async: Copying to clipboard was successful!'
                                        )
                                        // insert into db
                                        if (
                                            event.submitter.dataset
                                                .linkconnemailtarget
                                        ) {
                                            naja.makeRequest(
                                                'POST',
                                                '?url= ' +
                                                    url +
                                                    '&name=' +
                                                    name +
                                                    '&do=linkConnTemplate2Email',
                                                {
                                                    text: linkconntemplatevalue,
                                                    target: target,
                                                    invite: invite,
                                                    url: url,
                                                    name: name,
                                                    email: email,
                                                }
                                            )
                                        } else {
                                            naja.makeRequest(
                                                'POST',
                                                '?url= ' +
                                                    url +
                                                    '&name=' +
                                                    name +
                                                    '&do=linkConnTemplate2Link',
                                                {
                                                    text: linkconntemplatevalue,
                                                    target: target,
                                                    invite: invite,
                                                    url: url,
                                                    name: name,
                                                }
                                            )
                                        }
                                    },
                                    function (err) {
                                        console.error(
                                            'Async: Could not copy text: ',
                                            err
                                        )
                                    }
                                )
                            return false
                        },
                        false
                    )
                })
            }
        }
        onDomReady(() => linkconn(document))
        naja.snippetHandler.addEventListener('afterUpdate', (event) =>
            linkconn(event.detail.snippet)
        )
    }
}
