import onDomReady from './onDomReady'
// import { Dropdown } from "bootstrap";
require('./cc_conf.js')

// Example starter JavaScript for disabling form submissions if there are invalid fields
export class submitValidate {
    constructor() {
        console.log('constructor this.submitValidate')
    }
    initialize(naja) {
        const handleSubmits = (element) => {
            // Fetch all the forms we want to apply custom Bootstrap validation styles to
            const forms = element.querySelectorAll('.needs-validation')
            // console.log(forms);

            // Loop over them and prevent submission
            forms.forEach((form) => {
                form.addEventListener(
                    'submit',
                    (event) => {
                        console.log('checkValidity', form.checkValidity(), form)
                        if (!form.checkValidity()) {
                            event.preventDefault()
                            event.stopPropagation()
                        }
                        form.classList.add('was-validated')
                    },
                    false
                )
            })
        }

        onDomReady(() => handleSubmits(document))
        naja.snippetHandler.addEventListener('afterUpdate', (event) => {
            handleSubmits(event.detail.snippet)
        })
    }
}

export class charCount {
    constructor() {
        console.log('constructor this.charCount')
    }
    initialize(naja) {
        const charCount = (element) => {
            /**
             * char counter for textarea!
             */
            const textarea_wrappers =
                element.querySelectorAll('.counter-wrapper')
            if (textarea_wrappers) {
                textarea_wrappers.forEach((textarea_wrapper) => {
                    let characterCounter =
                        textarea_wrapper.querySelector('.currentCharCount')
                    let textarea_comment =
                        textarea_wrapper.querySelector('textarea')
                    console.log(textarea_comment, characterCounter)
                    if (characterCounter && textarea_comment) {
                        characterCounter.textContent =
                            textarea_comment.value.length
                        // console.log(maxNumOfChars);
                        textarea_comment.addEventListener('input', function () {
                            var numOfEnteredChars =
                                textarea_comment.value.length
                            characterCounter.textContent = numOfEnteredChars
                        })
                    }
                })
            }
        }
        onDomReady(() => charCount(document))
        naja.snippetHandler.addEventListener('afterUpdate', (event) => {
            charCount(event.detail.snippet)
        })
    }
}

export class ccRun {
    constructor() {
        console.log('constructor cookie-consent ccRun')
    }
    initialize(naja) {
        const ccRun = (element) => {
            // console.log("ccRun.ccRun", window.cc_conf, element);
            window.cc.run(window.cc_conf)
            // require("./cookieconsent-config.js");

            const cc_selector_elem = element.querySelector(
                '[data-cc="c-settings"]'
            )

            if (cc_selector_elem) {
                cc_selector_elem.addEventListener('click', (event) => {
                    console.log('click', window.cc, cc_selector_elem)
                    window.cc.show(true)
                    event.preventDefault()

                    // window.cc.run(cc_conf);
                    // html_elem.classList.toggle("show--consent");
                })
            }

            // obtain plugin
            // run plugin with your configuration
        }
        onDomReady(() => ccRun(document))
        // naja.snippetHandler.addEventListener("afterUpdate", (event) => {
        naja.addEventListener('complete', (event) => {
            // console.log(event, event.detail);
            // ccRun(event.detail.snippet);
        })
        naja.snippetHandler.addEventListener('afterUpdate', (event) => {
            ccRun(event.detail.snippet)
        })
    }
}

export class lb4bs5 {
    constructor() {
        console.log('constructor Lightbox2')
    }
    initialize(naja) {
        const lb4bs5 = (element) => {
            console.log('lb4bs5.lb4bs5')
            const options = {
                keyboard: true,
                size: 'xl',
                // gallery: "gallery",
                interval: 2000, // Galerieintervall
            }
            // return false;
            element
                // .querySelectorAll("[data-toggle='lightboxe']")
                .querySelectorAll('.lightbox-togglee')
                .forEach((el) => {
                    // console.log(el);
                    el.addEventListener('click', (e) => {
                        e.preventDefault()
                        console.log('click', e)
                        // const lightbox = new Lightbox(el, options);
                        // lightbox.show();
                        window.lightbox()
                        console.log('lightbox', lightbox)
                    })
                })
        }
        onDomReady(() => lb4bs5(document))
        naja.snippetHandler.addEventListener('afterUpdate', (event) => {
            // naja.addEventListener("complete", (event) => {
            lb4bs5(event.detail.snippet)
        })
    }
}

export class deleteConfirmModal {
    constructor() {
        console.log('deleteConfirmModal constructor Modal')
    }
    initialize(naja) {
        function OverlayQuestion(e, modal_toogle) {
            console.log('QUESTION', e)
            modal_toogle.toggle(true)
        }

        function OverlayQuestionCancel(e, modal_toogle) {
            console.log('CANCEL', e)
            modal_toogle.toggle(false)
        }

        function OverlayQuestionConfirm(e, target_delete_link, linkTnx) {
            console.log(
                'OverlayQuestionConfirm CONFIRM',
                e,
                target_delete_link,
                linkTnx
            )
            e.preventDefault()
            e.stopPropagation()

            naja.makeRequest('POST', target_delete_link, {})
                .then((payload) => {
                    naja.makeRequest('GET', linkTnx, null, {
                        forceRedirect: true,
                    }).then((payload) => {

                    })
                    
                })
                .catch((error) => {
                    /* handle error */
                })

            // window.location.replace(target_delete_link)
            return

            var request = new XMLHttpRequest()

            console.log('deletelink :', target_delete_link)

            request.onreadystatechange = function () {
                if (this.readyState == 4 && this.status == 200) {
                    console.log(this.responseText)
                }
            }
            request.open('POST', target_delete_link, true)
            request.send()

            window.location.replace(target_current_link)
        }

        const initModal = (element) => {
            console.log('deleteConfirmModal 2  initModal', element)

            if (element) {
                if (element.querySelector('#submitButton')) {
                    const target_modal =
                        element.querySelector('#submitButton').dataset.target
                    const modal_toogle = new window.bootstrap.Modal(
                        element.getElementById(target_modal)
                    )
                    const target_delete_link =
                        element.querySelector('#submitButton').dataset.link
                    const target_current_link =
                        element.querySelector('#submitButton').dataset.current
                    const linkTnx =
                        element.querySelector('#submitButton').dataset.tnx
                    const button = element.getElementById('submitButton')
                    const confirmBtn = element.getElementById('confirm')
                    const cancelBtn = element.getElementById('cancel')

                    button.addEventListener(
                        'click',
                        (e) => OverlayQuestion(e, modal_toogle),
                        true
                    )
                    cancelBtn.addEventListener(
                        'click',
                        (e) => OverlayQuestionCancel(e, modal_toogle),
                        true
                    )
                    confirmBtn.addEventListener(
                        'click',
                        (e) =>
                            OverlayQuestionConfirm(
                                e,
                                target_delete_link,
                                linkTnx
                            ),
                        true
                    )
                }
            }
        }
        onDomReady(() => initModal(document))
        naja.addEventListener('afterUpdate', (event) => {
            initModal(event.detail.snippet)
        })
    }
}
/*
export class dropdownSelect {
    constructor() {
        console.log("constructor adminSelect");
    }
    initialize(naja) {
        const handleSelect = (element) => {
            Array.from(element.querySelectorAll(".dropdown")).forEach(function (
                el
            ) {
                console.log(el);
                new Dropdown(el);
            });
        };

        onDomReady(() => handleSelect(document));
        naja.addEventListener("afterUpdate", (event) => {
            handleSelect(event.detail.snippet);
        });
    }
}
*/
export class scrollVimeoIntoView {
    constructor() {
        console.log('constructor scrollVimeoIntoView')
    }
    initialize(naja) {
        const handleClick = (element) => {
            if (window.innerWidth < 1024) {
                element
                    .querySelectorAll('.VideoListItem > a.nav-link')
                    .forEach((el) =>
                        el.addEventListener('click', (e) => {
                            console.log('scrollVimeoIntoView', e)
                            let vidView =
                                element.querySelector('#UltraTubePlaylist')
                            if (vidView) {
                                vidView.scrollIntoView()
                            }
                        })
                    )
            }
        }
        onDomReady(() => handleClick(document))
        naja.addEventListener('afterUpdate', (event) => {
            handleClick(event.detail.snippet)
        })
    }
}

export class ReadMore {
    constructor(readMoreSelector, wordsCount) {
        this.readMoreSelector = readMoreSelector || '.incodit-collapse-content'
        this.wordsCount = this.wordsCount || 30
        console.log('readMoreSelector', readMoreSelector)
    }

    initialize(naja) {
        const ReadMore = (element) => {
            console.log('ReadMore', window.$(this.readMoreSelector))
            // hole anz worte pro div und wenn mehr als wordsCount, dann zaubern

            element.querySelectorAll(this.readMoreSelector).forEach((ele) => {
                ele.addEventListener('click', function (eve) {
                    let followdiv = ele.querySelector('div')

                    console.log(ele, eve, followdiv)

                    eve.preventDefault()
                })
            })
        }

        onDomReady(() => ReadMore(document))
        naja.snippetHandler.addEventListener('afterUpdate', (event) =>
            ReadMore(event.detail.snippet)
        )
    }
}

import Happy from 'happy-inputs/src/Happy.js'
window.happy = Happy

export class DieHappy {
    constructor() {
        console.log('DieHappy')
    }

    initialize(naja) {
        const DieHappy = (element) => {
            console.log('DieHappy')
            let wh = new happy()
            wh.init()
            // hole anz worte pro div und wenn mehr als wordsCount, dann zaubern
        }

        onDomReady(() => DieHappy(document))
        naja.snippetHandler.addEventListener('afterUpdate', (event) =>
            DieHappy(event.detail.snippet)
        )
    }
}
