import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.js'
window.bootstrap = bootstrap

window.lightbox = require('lightbox2/src/js/lightbox')

require('lite-vimeo-embed')
// import * as CookieConsent from "vanilla-cookieconsent";
import Player from '@vimeo/player'
window.Player = Player
// global.jQuery = global.$ =
window.$ = window.jQuery = require('jquery')

/* == Benefits - Weiterlesen Btn == */

if (1) {
    ;(() => {
        var t
        ;(t = window.jQuery).fn.moreLess = function (e) {
            var a = t.extend(
                {
                    moreLabel: 'Read more',
                    lessLabel: 'Read less',
                    moreClass: '',
                    lessClass: '',
                    wordsCount: 50,
                },
                e
            )

            function s(t, e) {
                var s = t.text().trim().split(/\s+/)
                s.length > a.wordsCount &&
                    // console.log(s),
                    (s.splice(a.wordsCount, 9e9),
                    s.push(
                        '<a href="#" data-id="' +
                            e +
                            '" class="incodit-expand-content ' +
                            a.moreClass +
                            '">' +
                            a.moreLabel +
                            '</a>'
                    )),
                    t.html(s.join(' '))
            }
            var n = [],
                o = []
            this.each(function (e) {
                var a = t(this)
                n.push(a.html()), o.push(a), s(a, e)
            }),
                t(document).on(
                    'click',
                    '.incodit-expand-content',
                    function (e) {
                        e.preventDefault()
                        var s = t(this).attr('data-id')
                        o[s].html(
                            n[s] +
                                '<a href="#" data-id="' +
                                s +
                                '" class="incodit-collapse-content ' +
                                a.lessClass +
                                '">' +
                                a.lessLabel +
                                '</a>'
                        )
                    }
                ),
                t(document).on(
                    'click',
                    '.incodit-collapse-content',
                    function (e) {
                        e.preventDefault()
                        var a = t(this).attr('data-id')
                        s(o[a], a)
                    }
                )
        }
    })()

    $(function () {
        $('.entry-content').moreLess({
            moreLabel: '...Weiterlesen',
            lessLabel: '...Weniger',
            moreClass: 'read-more-link',
            lessClass: 'read-less-link',
            wordsCount: 50,
        })
    })
}

/* == MenuSidebar == */
if (0) {
    const menuButtons = document.querySelectorAll('.menu-button')
    const screenOverlay = document.querySelector('.main-layout .screen-overlay')

    // Toggle sidebar visibility when menu buttons are clicked
    menuButtons.forEach((button) => {
        button.addEventListener('click', () => {
            document.body.classList.toggle('sidebar-hidden')
        })
    })

    // Toggle sidebar visibility when screen overlay is clicked
    screenOverlay &&
        screenOverlay.addEventListener('click', () => {
            document.body.classList.toggle('sidebar-hidden')
        })

    // Show sidebar on large screens by default
    if (window.innerWidth >= 768) {
        document.body.classList.remove('sidebar-hidden')
    }

    function mySidebar(x) {
        if (x.matches) {
            // If media query matches
            document.body.style.backgroundColor = 'white'
        } else {
            document.body.classList.remove('sidebar-hidden')
        }
    }

    // Create a MediaQueryList object
    var x = window.matchMedia('(max-width: 768px)')

    // Call listener function at run time
    mySidebar(x)

    // Attach listener function on state changes
    x.addEventListener('change', function () {
        mySidebar(x)
    })
}
/** == SignIn - Fullheight Background ==

$(document).ready(function() {
    vph = $(window).height();
    $('.js-fullheight').css('height',vph);
});

$(window).resize(function(){
    vpw = $(window).width();
    vph = $(window).height();
    
    $('.js-fullheight').css('height',vph);
}); */
