
window.cc_conf = {
    guiOptions: {
        consentModal: {
            layout: "box",
            position: "bottom left",
            equalWeightButtons: true,
            flipButtons: false,
        },
        preferencesModal: {
            layout: "box",
            position: "right",
            equalWeightButtons: true,
            flipButtons: false,
        },
    },
    categories: {
        necessary: {
            readOnly: true,
        },
        analytics: {},
    },
    language: {
        default: "de",
        autoDetect: "browser",
        translations: {
            de: {
                consentModal: {
                    title: "Verwendung von Cookies",
                    description:
                        'Wir verwenden Cookies, um die grundlegenden Funktionen der Website zu gewährleisten  und um Ihr Online-Erlebnis zu verbessern. Sie können für jede Kategorie wählen, ob Sie sich ein- oder austragen möchten. Für weitere Einzelheiten zu Cookies und anderen sensiblen Daten lesen Sie bitte die vollständige <a class="cc-link" href="/datenschutz">Datenschutzerklärung</a> <button type="button" data-cc="c-settings" class="cc-link">Cookie-Einstellungen</button>',
                    acceptAllBtn: "Alle akzeptieren",
                    acceptNecessaryBtn: "Alle ablehnen",
                    showPreferencesBtn: "Cookie-Einstellungen",
                    footer: '<a href="#link">Privacy Policy</a>\n<a href="#link">Terms and conditions</a>',
                },
                preferencesModal: {
                    title: "Cookie-Einstellungen",
                    acceptAllBtn: "Alle akzeptieren",
                    acceptNecessaryBtn: "Alle ablehnen",
                    savePreferencesBtn: "Speichern",
                    closeIconLabel: "Schließen",
                    serviceCounterLabel: "Service|Services",
                    sections: [
                        {
                            title: "Cookie-Einstellungen",
                            description:
                                'Wir verwenden Cookies, um die grundlegenden Funktionen der Website zu gewährleisten und um Ihr Online-Erlebnis zu verbessern. Sie können für jede Kategorie wählen, ob Sie sich ein- oder austragen möchten. Für weitere Einzelheiten zu Cookies und anderen sensiblen Daten lesen Sie bitte die vollständige <a href="/datenschutz" class="cc-link">Datenschutzerklärung.</a>.',
                        },
                        {
                            title: 'Notwendige Cookies <span class="pm__badge">Immer aktiv</span>',
                            description:
                                "Diese Cookies sind für das ordnungsgemäße Funktionieren meiner Website unerlässlich. Ohne diese Cookies würde die Website nicht richtig funktionieren",
                            linkedCategory: "necessary",
                        },
                        {
                            title: "Leistungs- und Analyse Cookies",
                            description:
                                "Google Analytics Cookies helfen uns zu verstehen, wie Besucher mit unseren Webseiten interagieren, indem sie anonyme Informationen sammeln und Berichte darüber erstellen. Diese Berichte ermöglichen es uns, die Nutzung der Website zu analysieren und Verbesserungen vorzunehmen, um Ihr Online-Erlebnis zu optimieren. Durch die Nutzung unserer Website erklären Sie sich mit der Verwendung   von Google Analytics Cookies zu diesem Zweck einverstanden.",
                            linkedCategory: "analytics",
                            cookieTable: {
                                headers: {
                                    name: "Name",
                                    domain: "Service",
                                    description: "Description",
                                    expiration: "Expiration",
                                },
                                body: [
                                    {
                                        name: "_ga",
                                        domain: "Google Analytics",
                                        description:
                                            'Cookie set by <a href="#das">Google Analytics</a>',
                                        expiration: "Expires after 12 days",
                                    },
                                    {
                                        name: "_gid",
                                        domain: "Google Analytics",
                                        description:
                                            'Cookie set by <a href="#das">Google Analytics</a>',
                                        expiration: "Session",
                                    },
                                ],
                            },
                        },
                    ],
                },
            }
        },
    },
};