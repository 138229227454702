import onDomReady from "./onDomReady";

export class ProfileImage {
    constructor() {}
    initialize(naja) {
        /* ==========================================
         *        PREVIEW FÜR BILDERPOOL
         * ========================================== */
        const imagePreviewEnable = (element) =>  {
            let galleries = element.querySelectorAll(".imagePool");

            // Funktion zum Anzeigen der Bildvorschau basierend auf der Auswahl
            function updateImagePreview(event) {
                console.log("image preview pool, event", event);
                let formNumber = event.currentTarget.getAttribute("id");
                console.log("formNumber", formNumber, event.currentTarget);
                let selectedImage = element.querySelector(
                    'input[name="imagePool' + formNumber + '"]:checked'
                );
                console.log('input[name="imagePool' + formNumber + '"]');
                if (! selectedImage) {
                    selectedImage = document.querySelector(
                        'input[name="imagePool' + formNumber + '"]:checked'
                    );
                }
                console.log("selectedImage", selectedImage);
                // if(selectedImage.id !== null)
                let selectedId = selectedImage.getAttribute("id");
                console.log(selectedId);
                let imageUrl =
                    selectedImage.nextElementSibling.querySelector("img").src;
                let imagePreview = document.getElementById(
                    "imagePreview" + formNumber
                );
                console.log(imagePreview, "imagePreview" + formNumber);
                let imagePreviewHidden = document.getElementById(
                    "imagePreviewHidden" + formNumber
                );
                // Bildvorschau aktualisieren
                imagePreview.src = imageUrl;
                imagePreview.value = selectedId;
                imagePreviewHidden.value = "media:" + selectedId;
                console.log(
                    imagePreviewHidden,
                    selectedId,
                    imagePreviewHidden.value
                );
            }

            // Initial die Bildvorschau anzeigen
            // updateImagePreview({ currentTarget: galleries[0] });

            // Ereignislistener für Änderungen in der Radioliste hinzufügen
            galleries.forEach(function (gallery) {
                gallery.addEventListener("click", updateImagePreview);
            });

            /* ==========================================
             *        PREVIEW FÜR BILDUPLOADS
             * ========================================== */
            let imageInput = document.querySelectorAll(".imageInput");
            imageInput.forEach(function (input) {
                input.addEventListener("click", resetActiveInput, true);
                input.addEventListener("change", previewImage, true);
            });

            function previewImage(event) {
                console.log("image previewImage element:", element);
                let formNumber = event.target.getAttribute("id");
                let input = event.target;
                
                let image = document.getElementById(
                    "imagePreview" + formNumber
                );
                console.log(input, formNumber, image);
                if (! image)  {
                    image = document.getElementById(
                        "imagePreviewpool1"
                    );
                }
                console.log(input, formNumber, image);
                if (input.files && input.files[0]) {
                    let reader = new FileReader();
                    reader.onload = function (e) {
                        image.src = e.target.result;
                    };
                    reader.readAsDataURL(input.files[0]);

                    // reseten, damit das hochgeladene bild gewählt ist
                    let imagePreviewHidden = document.getElementById(
                        "imagePreviewHidden" + formNumber
                    );
                    if (imagePreviewHidden) imagePreviewHidden.value = "";
                }
            }
            /* ==========================================
             *        FILTER FÜR BILDERPOOL
             * ========================================== */
            const portfolios = document.querySelectorAll(".portfolio");

            portfolios.forEach(function (portfolio) {
                const filterButtons = portfolio.querySelectorAll(".filter-btn");
                const galleryItems = portfolio.querySelectorAll(".galleryitem");

                filterButtons.forEach(function (button) {
                    button.addEventListener("click", function () {
                        const filterValue = button.getAttribute("data-filter");

                        // Filter anwenden
                        galleryItems.forEach(function (item) {
                            if (
                                filterValue === "all" ||
                                item.classList.contains(filterValue)
                            ) {
                                item.style.display = "block";
                            } else {
                                item.style.display = "none";
                            }
                        });
                    });
                });
            });

            function resetActiveInput(event) {
                //if (activeInput === 'imagePool') {
                // Setze den Event-Listener für input1 zurück
                imageInput.forEach(function (input) {
                    var formNumber = event.target.getAttribute("id");
                    var input = event.target;
                    console.log(input, formNumber);
                    // var image = document.getElementById('imagePreview' + formNumber);
                    input.value = "";
                });
            }
        }
        onDomReady(() => imagePreviewEnable(document));
        naja.snippetHandler.addEventListener("afterUpdate", (event) => imagePreviewEnable(event.detail.snippet));
    }
}
