// import '../scss/dashboard.scss';
import "../scss/admin.scss";

// NICHT NUTZEN klappt nicht
// import Happy from "happy-inputs/src/nomodule-es5-fallback";
// import Happy from "happy-inputs/src/Happy.js";
// window.happy = new Happy;
//  happy.init();

import "jquery";
import "bootstrap/dist/js/bootstrap.bundle";
import "bootstrap-datepicker/js/bootstrap-datepicker";
import "./jquery-ui-custom.js";
import "../../vendor/ublaboo/datagrid/assets/datagrid-instant-url-refresh.js";
import "../../vendor/ublaboo/datagrid/assets/datagrid-spinners.js";

import naja from "naja";

import "../../vendor/ublaboo/datagrid/assets/datagrid.js";
import "nette-forms";
import "bootstrap-select";

import "./shared.js";
import {submitValidate,charCount, DieHappy} from './sharedImports.js'

import "./dashboard.min.js";
import Shuffle from "shufflejs";
window.Shuffle = Shuffle;

import "./custom.js";
import {addCustomer} from "./addCustomer.js";
import {ProfileImage} from  "./profile.js";
import {Imagepool} from "./shuffleadmin.js";
import {copy2clipboard} from "./copy2clipboard.js";
import {SpinnerExtension} from "./SpinnerExtension.js";
import {deleteConfirmModal} from "./sharedImports.js";
// import { SpinnerExtension } from '@peckadesign/pd-naja'
import { linkconn } from "./linkconn.js";

import {trumbo} from  "./trumbo.js";


// console.log("#loading", document.querySelector("#loading"));
naja.registerExtension(new SpinnerExtension("#loading"));
naja.registerExtension(new Imagepool());
naja.registerExtension(new ProfileImage());
naja.registerExtension(new addCustomer());
naja.registerExtension(new copy2clipboard());
naja.registerExtension(new submitValidate());
 
naja.registerExtension(new deleteConfirmModal());
naja.registerExtension(new charCount());
// naja.registerExtension(new ccRun());
naja.registerExtension(new linkconn());
naja.registerExtension(new trumbo());
naja.registerExtension(new DieHappy());
naja.initialize();

// console.log("naja", naja);
