
import onDomReady from "./onDomReady";

export class addCustomer {
    constructor() {
        console.log("constructor addCustomer");
    }

    initialize(naja) {
        const toggleType = (element) => {
            var selectBox = element.querySelector("#userRole");
            console.log(selectBox);
            if (selectBox) {
                selectBox.addEventListener("change", () => {
                    var selectedOption =
                        selectBox.options[selectBox.selectedIndex].value;
                    var personForm = element.getElementById("personForm");
                    var companyForm = element.getElementById("companyForm");

                    // Überprüfe, ob die ausgewählte Option das gewünschte Attribut ist
                    if (selectedOption === "person") {
                        personForm.style.display = "block"; // zeige personen form elemente
                        companyForm.style.display = "none"; // verstecke company form elemente
                    } else {
                        personForm.style.display = "none"; // verstecke person form elemente
                        companyForm.style.display = "block"; // zeige company form elemente an
                    }
                });
            }
        };

        onDomReady(() => toggleType(document));
        naja.snippetHandler.addEventListener("afterUpdate", (event) => {
            toggleType(event.detail.snippet);
        });
    }
}
