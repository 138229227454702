import onDomReady from "./onDomReady";

export class copy2clipboard {
    constructor(dom) {
        console.log("constructor copy2clipboard dom: ", dom);
    }

    initialize(naja) {
        const fallbackCopyTextToClipboard = (text) => {
            let textArea = document.createElement("textarea");
            textArea.value = text;

            // Avoid scrolling to bottom
            textArea.style.top = "0";
            textArea.style.left = "0";
            textArea.style.position = "fixed";

            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            try {
                var successful = document.execCommand("copy");
                var msg = successful ? "successful" : "unsuccessful";
                console.log("Fallback: Copying text command was " + msg);
            } catch (err) {
                console.error("Fallback: Oops, unable to copy", err);
            }

            document.body.removeChild(textArea);
        };
        let rrr;
        const handleClick = (e) => {
            console.log(e);
            // e.preventDefault();
            // e.stopPropagation();
            console.log(
                e,
                e.target.getAttribute("data-target"),
                e.target.parentNode.getAttribute("data-target")
            );
            const target = e.target.getAttribute("data-link");
            const target_modal = e.target.getAttribute("data-target");
            rrr = new bootstrap.Modal(document.getElementById(target_modal));
            rrr.toggle();
            console.log(target);
            if (!navigator.clipboard) {
                fallbackCopyTextToClipboard(target);
                return;
            }
            navigator.clipboard.writeText(target).then(
                function () {
                    console.log("Async: Copying to clipboard was successful!");
                },
                function (err) {
                    console.error("Async: Could not copy text: ", err);
                }
            );

            return true;
        };

        const copy2clipboard = (wind) => {
            let alinks = wind.querySelectorAll(".copy2clipboard");
            // console.log(alinks);
            Array.from(alinks).forEach(function (alink) {
                alink.addEventListener("click", handleClick, true);
            });
        };

        onDomReady(() => copy2clipboard(document));
        naja.snippetHandler.addEventListener("afterUpdate", (event) =>
            copy2clipboard(event.detail.snippet)
        );
    }
}
